import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

interface EmailPreviewFooterProps {
  readonly disclaimer: string;
  readonly language: string;
  readonly isPaymentLink?: string;
  readonly isCurrentThemeDark?: boolean;
}

export function EmailPreviewFooter(props: EmailPreviewFooterProps): ReactNode {
  const intl = useIntl();

  const { disclaimer, isPaymentLink, isCurrentThemeDark, language, ...rest } = props;

  const path: string = isCurrentThemeDark
    ? '/illustrations/receivable-invoices/qonto_logo_flower-dark.gif'
    : '/illustrations/receivable-invoices/qonto_logo_flower.gif';

  return (
    <div className={cx(styles['email-preview-footer'])} data-test-preview-email-footer {...rest}>
      <div className={cx(styles['qonto-flower'], 'mb-16')}>
        <a
          href={intl.formatMessage(
            { id: 'receivable-invoices.share-email.preview.qonto-link' },
            { language }
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticThemedAsset assetPath={path} />
        </a>
      </div>

      <div className={cx(isPaymentLink ? 'title-4 body-1' : 'mb-32')}>
        {intl.formatMessage({ id: 'receivable-invoices.share-email.preview.slogan' })}
      </div>
      <div
        className={cx(styles['footer-text'], 'caption mb-32')}
        dangerouslySetInnerHTML={{ __html: disclaimer }}
        data-test-preview-email-footer-disclaimer
      />
      <div
        className={cx(styles['footer-text'], 'caption mb-32')}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: 'receivable-invoices.share-email.preview.address' }),
        }}
      />
    </div>
  );
}
