import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface InvoiceProps {
  id: string;
  date: string;
  showSignus: boolean;
  currency: string;
  name: string;
  selectedInvoiceId: string;
  handleSelectInvoice: (invoiceId: string) => void;
  amount?: number | undefined;
}

export function InvoiceDetailsCard({
  id,
  currency,
  name,
  showSignus,
  date,
  amount,
  selectedInvoiceId,
  handleSelectInvoice,
}: InvoiceProps): ReactNode {
  const { locale } = useIntl();
  const { formatMessage } = useIntl();

  const formattedDate = dateToken({
    date,
    locale,
    token: 'date-time-s',
  });

  const isSelected = selectedInvoiceId === id;

  const formattedAmount = (): string => {
    if (amount === undefined) {
      return formatMessage({ id: 'receivable-invoices.label.missing-data' });
    }

    const sign = showSignus ? '-' : '';
    return `${sign}${amount.toString()}`;
  };

  return (
    <button
      type="button"
      onClick={() => {
        handleSelectInvoice(id);
      }}
      className={cx(styles.card, isSelected ? styles.selected : styles.unselected)}
      data-test-invoice-details-card-button
      key={id}
    >
      <div className={cx(styles.titleRow)}>
        <span className="body-1" data-test-invoice-details-name>
          {name}
        </span>
        <span
          className={cx(['body-1', !amount ? styles.greyedOut : ''])}
          data-test-invoice-details-amount
        >
          {formattedAmount()} {currency}
        </span>
      </div>
      <div className={cx(styles.date)}>
        <time data-test-invoice-details-date>{date ? formattedDate : '-'}</time>
      </div>
    </button>
  );
}
